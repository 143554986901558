import { createMuiTheme } from '@material-ui/core/styles'

const white = '#fffef5'
const black = '#19171a'
const primaryMain = '#19171a'
const primaryDark = '#302d32'
const grey = '#d0d0d0'
const greyMedium = '#f0eddb'
const ternaryMain = '#c9d2b9'
const link = '#828777'

const imp = '!important'

const hpTitle = {
  fontSize: '26px !important',
  fontWeight: '400 !important',
  lineHeight: '1.2 !important',
  textAlign: 'center',
  fontFamily: "'GlamourAbsolute', serif",
  '&>b, &>strong': {
    fontWeight: '400 !important',
  },
  '@media (min-width: 1024px)': {
    fontSize: '40px !important',
  },
}
const hpTitleSmall = {
  fontSize: '25px !important',
  lineHeight: '1.2 !important',
  fontWeight: '400 !important',
  fontFamily: "'GlamourAbsolute', serif",
  '&>b, &>strong': {
    fontWeight: '400 !important',
  },
  '@media (min-width: 1024px)': {
    fontSize: '35px !important',
  },
}

const customSelect = {
  fontSize: `14px ${imp}`,
  border: `1px solid ${grey} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${white} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'Fira Sans',
    secondary: 'GlamourAbsolute',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1320,
    },
  },
  typography: {
    fontFamily: "'Fira Sans', helvetica, sans-serif",
    h1: {
      fontSize: 35,
      lineHeight: 1.125,
      fontWeight: 400,
      fontFamily: "'GlamourAbsolute', serif",
      '&>b, &>strong': {
        fontWeight: 400,
      },
      '@media (min-width: 1024px)': {
        fontSize: 45,
      },
    },
    h2: {
      fontSize: 30,
      lineHeight: 1.2,
      fontWeight: 400,
      fontFamily: "'GlamourAbsolute', serif",
      '&>b, &>strong': {
        fontWeight: 400,
      },
      '@media (min-width: 1024px)': {
        fontSize: 40,
      },
    },
    h3: {
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 400,
      fontFamily: "'GlamourAbsolute', serif",
      '&>b, &>strong': {
        fontWeight: 400,
      },
      '@media (min-width: 1024px)': {
        fontSize: 30,
      },
    },
    h4: {
      fontSize: 12,
      lineHeight: 1.25,
      '@media (min-width: 1024px)': {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 11,
      lineHeight: 1.15,
      '@media (min-width: 1024px)': {
        fontSize: 14,
      },
    },
    h6: {
      fontSize: 10,
      lineHeight: 1.15,
      '@media (min-width: 1024px)': {
        fontSize: 12,
      },
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 14,
      lineHeight: 0.8,
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      lineHeight: 1,
      fontWeight: 400,
      fontFamily: "'Fira Sans', helvetica, sans-serif",
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: primaryMain,
      dark: primaryDark,
      contrastText: white,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#D54117',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: ternaryMain,
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: ternaryMain,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 97,
    logoWidth: 78,
    logoHeight: 32,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: white,
    greyMedium: greyMedium,
    greyText: '#333',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.input': {
          borderColor: `${grey} !important`,
          borderRadius: '0 !important',
        },
        '.select': {
          ...customSelect,
        },
        '.label': {
          fontSize: '12px !important',
          textTransform: 'uppercase',
        },
        '.banner': {},
        '.header': {
          '&__layout': {
            borderBottom: `.0625rem solid ${grey} !important`,
          },
          '&__logo': {
            width: '78px !important',
            height: '32px !important',
          },
          '&__cta, & .menu, & .menu__link': {
            color: `${black} !important`,
          },
          '&:hover': {
            backgroundColor: `${white} !important`,
            '& *': {
              color: `${black} !important`,
            },
          },
        },
        '#cart-menu': {
          '& .cart': {
            backgroundColor: `${greyMedium} !important`,
          },
        },
        '.basket__btn': {
          '&.MuiButton-root': {
            '& .MuiButton-label': {
              '&>div': {},
              '& .basket__icon': {
                maxHeight: '20px !important',
                width: 'auto !important',
              },
            },
          },
        },
        '.page--home': {
          '@media (min-width: 1024px)': {
            '& .basket': {
              top: 150,
            },
          },
        },
        '.hero__home': {
          '& .daterange': {
            border: '0!important',
          },
          '& .title': {
            '@media (min-width: 1024px)': {
              fontSize: '60px !important',
            },
          },
          '& .tagline': {
            fontSize: '16px !important',
          },
        },
        '.daterange': {
          border: `1px solid ${black} !important`,
          '&__btn': {
            fontSize: '16px !important',
          },
        },
        '.hero': {},
        '.size': {
          '&-modal': {
            '&__close': {
              backgroundColor: `${black} !important`,
            },
            '&__head, &__foot': {
              backgroundColor: `${greyMedium} !important`,
            },
          },
          '&-card': {
            '&__status': {
              marginTop: '8px !important',
              fontSize: '12px !important',
              fontWeight: '700 !important',
            },
          },
        },
        '.productCard': {},
        '.product': {
          '&__bottom': {
            '&-item': {
              fontSize: '12px !important',
              '@media (min-width: 1024px)': {
                fontSize: '16px !important',
              },
            },
            '&-link': {
              color: `${link} !important`,
            },
          },
        },
        '.pack': {},
        '.commitment': {
          '&__title': {
            fontSize: '24px !important',
          },
        },
        '.cart': {
          '&-list': {
            border: `0 !important`,
            backgroundColor: `none !important`,
          },
          '&-badge': {
            border: `0 !important`,
            fontSize: '11px !important',
            minWidth: '18px !important',
            width: '18px !important',
            height: '18px !important',
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: '0!important',
            '&:last-child': {
              marginBottom: '32px !important',
              '@media (min-width: 1024px)': {
                marginBottom: '64px !important',
              },
            },
          },
          '&__question': {
            fontSize: '16px !important',
            borderBottomColor: `${grey} !important`,
            marginBottom: '0!important',
            '&[aria-expanded=true]': {},
          },
          '&__btn': {
            border: '0!important',
          },
          '&__answer': {},
          '&-content': {
            maxWidth: '1024px !important',
            '@media (min-width: 1024px)': {
              paddingBottom: '50px !important',
            },
          },
          '&-page': {
            background: '#efeddb !important',
            '&__cat': {
              textTransform: 'none!important',
              '@media (min-width: 1024px)': {
                margin: '50px 0 !important',
              },
            },
          },
        },
        '.product__area': {},
        '.testimonial': {},
        '.hiw, .testimonial': {},
        '.hiw': {
          '&__title': {
            fontWeight: '700 !important',
            fontSize: '16px !important',
            '@media (min-width: 1024px)': {
              fontSize: '20px !important',
            },
          },
        },
        '.discover': {
          '&__card, &__shadow': {},
        },
        '.discover-product': {
          '&__content': {
            background: `${ternaryMain} !important`,
          },
        },
        '.sizes': {
          '&__btn': {
            '&:not([disabled])': {
              background: `${ternaryMain} !important`,
              '&:hover, &:focus': {
                background: `${black} !important`,
              },
            },
          },
          '&__chip': {
            top: '-18px !important',
            right: '-25px !important',
          },
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {
              ...hpTitle,
              textAlign: 'inherit',
            },
            '& h3': {
              ...hpTitleSmall,
            },
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          '&__subtitle, &__subtext': {
            ...hpTitle,
          },
        },
        '.section-testimonials': {},
        '.flexContent': {},
        '.footer': {
          '&__top': {
            background: '#f0eddb !important',
          },
        },
        '.page': {
          '&__hero': {
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '@media (min-width: 1024px)': {
              maxWidth: '890px !important',
            },
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
        },
        '.funnel': {
          '&__root': {
            border: `1px solid ${grey} !important`,
          },
          '&__nav': {
            backgroundColor: `${greyMedium} !important`,
            '&--active': {
              borderColor: 'transparent !important',
            },
          },
          '& .basket': {
            border: `1px solid ${grey} !important`,
          },
        },
        '.account': {
          '@media (min-width: 1024px)': {
            overflow: 'hidden',
            '&:before': {
              content: "''",
              zIndex: 1,
              position: 'absolute',
              top: '-41rem',
              right: '-8.5rem',
              width: '95.125rem',
              height: '79.8125rem',
              borderRadius: '1.875rem',
              background: '#f0eddb',
              transform: 'rotate(14deg)',
            },
            '&> div': {
              position: 'relative',
              zIndex: 2,
            },
          },
          '&__box': {
            border: `1px solid ${grey}`,
          },
        },
        '.footer__logo': {
          maxHeight: '80px !important',
        },
        '.contact-page': {
          '& .contact_select': {
            border: '1px solid #d0d0d0',
          },
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        padding: '12px 40px',
      },
      label: {
        position: 'relative',
        zIndex: 2,
      },
      sizeSmall: {
        fontSize: 12,
        padding: '8px 20px',
      },
      sizeLarge: {
        fontSize: 16,
        padding: '12px 40px',
      },
      contained: {
        '&:hover, &:focus, &:active, &.selected': {},
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
      outlinedPrimary: {},
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
    MuiDivider: {
      root: {
        backgroundColor: grey,
      },
    },
  },
})

export default theme
